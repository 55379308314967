@use 'sass:list';
@use 'sass:math';
@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.Process {

    h2 {
        color: #E09033 !important;
    }

    $colors:  #BA0C2F, #ED8B00, #007A86, #8A387C;
    $widths:  100%,    33%,     33%,     33%;
    $offsets: 0%,      0%,      33%,     66%;

    .timeline {
        .block {
            padding: $dims_padding-small;

            text-align: center;

            border-radius: layout.$border-radius--large;
            box-shadow: layout.$box_shadow--large;
            cursor: pointer;
            transition: background animation.$speed;

            @for $i from 1 through length($colors) {
                &:nth-of-type(#{$i}) {
                    left: list.nth($offsets, $i);

                    width: list.nth($widths, $i);

                    background: rgba(list.nth($colors, $i), .05);
                    border: 4px solid list.nth($colors, $i);

                    &.active {
                        background: rgba(list.nth($colors, $i), .35);
                    }
                }
            }

            + .block {
                margin-top: $dims_padding-small;
            }

            h4, h5 {
                margin: 0;

                font-size: $font_size-small;
            }

            h5 {
                font-weight: 400;
            }

            h4 + h5 {
                margin-top: $dims_padding-small * .6;
            }
        }
    }

    .Accordion {
        main {
            h3 {
                margin: 0;
                font-size: $font_size-small !important;
            }

            p {
                margin: math.div($dims_padding-small, 2) 0 $dims_padding-small * 2;
                line-height: 1.4em !important;
            }
        }
    }

    .share-link {
        display: block;

        margin: $dims_padding-small 0;

        color: $color_brand;
        text-decoration: underline;
    }

}
